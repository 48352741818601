/* WordBank.css */

.word-bank-container {
  margin: 50px auto;
  max-width: 600px;
  padding: 0 20px;
}

.word-bank-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.letters-container {
  display: flex;
  flex-wrap: wrap;
}

.letter {
  font-size: 18px;
  margin-right: 10px; /* Add space between letters */
  cursor: pointer; /* Add cursor pointer */
}

.selected-letter {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px; /* Add space between letters */
  cursor: pointer; /* Add cursor pointer */
}

.word-list {
  list-style: none;
  padding: 0;
}

.word-list-item {
  margin-bottom: 10px;
}

.word-link {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  transition: color 0.3s ease;
}

.word-link:hover {
  color: #2596BE; /* Change color on hover */
}
